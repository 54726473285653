import T from "../../../components/forms/TextField.module.css";

import type { ReactNode } from "react";
import { Input, Label, Text, TextField } from "react-aria-components";
import type { FieldError, FieldValues, Path, RegisterOptions, UseFormReturn } from "react-hook-form";
import { useAriaField } from "../../../components/forms/ariaFieldRegister.ts";
import { requiredMessage } from "../../../components/forms/requiredMessage.ts";

//#region Layouts

export function DescriptionLayout({
    children,
    label,
    description,
}: {
    children: ReactNode;
    label: ReactNode;
    description: ReactNode;
}) {
    return (
        <>
            <Label className={T.Label}>{label}</Label>
            <div className="flex gap-2">
                <div>{children}</div>
                <Description>{description}</Description>
            </div>
        </>
    );
}

export function Description({ children }: { children: ReactNode }) {
    return (
        <Text slot="description" className="text-muted font-size-small">
            {children}
        </Text>
    );
}

//#endregion

//#region Error display

export function ErrorText({ text }: { text: ReactNode | undefined }) {
    return text != null && <div className="text-error">{text}</div>;
}

export function ErrorTexts({ errors }: { errors: ReactNode[] }) {
    return (
        <>
            {errors.map((error, i) => (
                <ErrorText key={i} text={error} />
            ))}
        </>
    );
}

/** Display an error for a react-hook-form field */
export function FormFieldError({ error }: { error?: FieldError | undefined }) {
    return error && <ErrorText text={error.message} />;
}

//#endregion

//#region Standard fields

export function CardNameField<T extends FieldValues>(props: Omit<StandardFieldProps<T>, "label" | "options">) {
    const minChars = 4;
    return (
        <StandardField
            label={<>Name (Min {minChars} chars)</>}
            options={{
                required: requiredMessage,
                setValueAs: (v: string) => v.trim(),
                validate: (v) => v.length >= minChars || "Name is too short",
            }}
            {...props}
        />
    );
}

export interface StandardFieldProps<T extends FieldValues> {
    form: UseFormReturn<T>;
    name: Path<T>;
    label: ReactNode;
    options: RegisterOptions<T>;
    placeholder?: string;
    autoFocus?: boolean;
}

export function StandardField<T extends FieldValues>({
    form,
    name,
    label,
    options,
    placeholder,
    autoFocus,
}: StandardFieldProps<T>) {
    const { props, ref } = useAriaField(name, form, options);

    return (
        <TextField className={T.TextField} autoFocus={autoFocus} {...props}>
            <Label className={T.Label}>{label}</Label>
            <Input className={T.Input} ref={ref} placeholder={placeholder} />
            <ErrorText text={props.errorMessage} />
        </TextField>
    );
}

//#endregion
