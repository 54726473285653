import type { PaymentResultResponse } from "@warrenio/api-spec/spec.oats.gen";

export class ClictopayError extends Error {
    name = "ClictopayError";

    constructor(
        public readonly code: "failed" | "other",
        message: string,
    ) {
        super(message);
    }
}

export function parseClictopayPaymentResult(response: PaymentResultResponse) {
    switch (response.status) {
        case "success":
            return response;
        case "failed":
            throw new ClictopayError("failed", response.message ?? "Payment failed");
        default:
            return null; // status still pending
    }
}
