import type {
    LinkParamsGeneric,
    MethodConfig,
    ProcessorConfig,
    SslcommerzRedirectSendDataResponse,
} from "@warrenio/api-spec/spec.oats.gen";
import { useImperativeHandle } from "react";
import invariant from "tiny-invariant";
import { useUnmountSignal } from "../../../utils/react/useUnmountSignal.tsx";
import { useApiClient } from "../../api/apiClient.store.ts";
import type { TopUpParams, TopUpProps } from "../topup/TopUpParams.ts";
import { getRedirectUrlFromData, linkSendData, paymentResultRequest, pollForSuccess } from "../topup/topUpUtils.ts";
import { parseClictopayPaymentResult } from "./parseClictopayPaymentResult.ts";

export type ClictopayLinkProcessorConfig = ProcessorConfig & { type: "link"; keyword: "clictopay" };

export function ClictopayTopUpForm({
    actionsRef,
    method: { key },
}: {
    config: ClictopayLinkProcessorConfig;
    method: MethodConfig;
} & TopUpProps) {
    //#region Hooks

    const apiClient = useApiClient();
    const signal = useUnmountSignal();

    useImperativeHandle(actionsRef, () => ({
        needsPopUp(_params) {
            return true;
        },

        async topUp(params: TopUpParams) {
            const { progress, popup, account } = params;

            progress("Starting payment");
            const sendResult = await linkSendData<LinkParamsGeneric>(apiClient, params, "clictopay", {
                paymentMethod: key,
            });

            const data = sendResult.data as SslcommerzRedirectSendDataResponse;
            const { persistent_data_ref } = data;

            invariant(persistent_data_ref, "Expected persistent_data_ref in response");

            progress("Redirecting to payment gateway");
            invariant(popup, "Popup must be provided");
            popup.handleRedirect(getRedirectUrlFromData(data));

            // await popup.returnFromRedirect(signal);

            progress("Waiting for payment confirmation");
            return await pollForSuccess({
                signal,
                popup,
                check: async () =>
                    parseClictopayPaymentResult(
                        await paymentResultRequest(apiClient, account.id, persistent_data_ref, signal),
                    ) != null,
            });
        },
    }));
    //#endregion

    return null;
}

export default ClictopayTopUpForm;
