import type { QueryClient } from "@tanstack/query-core";
import type {
    CreditBuyBody,
    LinkParams,
    LinkProcessorId,
    LinkSendDataResponse,
} from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import sleep from "@warrenio/utils/promise/sleep";
import invariant from "tiny-invariant";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { invalidateQueries } from "../../../utils/query/invalidateQueries.ts";
import { UnmountedError } from "../../../utils/react/useUnmountSignal.tsx";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";
import * as billingAccountQuery from "../billingAccountQuery.ts";
import * as billingHistoryQuery from "../billingHistoryQuery.ts";
import * as billingInvoicesQuery from "../billingInvoicesQuery.ts";
import type { PaymentPopup } from "./popup.ts";
import type { TopUpParams } from "./TopUpParams.ts";

/** Initiate the top-up process for a link-type processor */
export async function linkSendData<T extends LinkParams>(
    apiClient: ApiClient,
    { account, amount, returnUrl }: TopUpParams,
    processor: LinkProcessorId,
    params: Omit<T, "referrer_url">,
): Promise<LinkSendDataResponse> {
    const billing_account_id = account.id;

    const extendedParams = {
        referrer_url: returnUrl,
        ...params,
    } satisfies LinkParams;

    // TODO: Check `success` and `error_msg` fields?
    return getResponseData(
        await apiClient.POST("/payment/link/send_data", {
            ...jsonEncodedBody,
            body: {
                billing_account_id,
                amount,

                params: JSON.stringify(extendedParams),
                processor,
            },
        }),
    );
}

export async function creditBuyRequest(apiClient: ApiClient, body: CreditBuyBody) {
    return getResponseData(
        await apiClient.POST("/payment/credit/buy", {
            ...jsonEncodedBody,
            body,
        }),
    );
}

export async function persistentDataRequest(
    apiClient: ApiClient,
    billing_account_id: number,
    identifier: string,
    signal: AbortSignal,
) {
    return getResponseData(
        await apiClient.GET("/payment/persistent_data", {
            params: {
                query: { billing_account_id, identifier },
            },
            signal,
        }),
    );
}

export async function paymentResultRequest(
    apiClient: ApiClient,
    billing_account_id: number,
    identifier: string,
    signal: AbortSignal,
) {
    return getResponseData(
        await apiClient.GET("/payment/link/process_result", {
            params: {
                query: { billing_account_id, identifier },
            },
            signal,
        }),
    );
}

export async function creditListRequest(apiClient: ApiClient, billing_account_id: number, signal: AbortSignal) {
    return getResponseData(
        await apiClient.GET("/payment/credit/list", {
            signal,
            params: { query: { billing_account_id } },
        }),
    );
}

export async function finalTopupAmountRequest(
    apiClient: ApiClient,
    params: {
        billing_account_id: number;
        processor: string;
        amount: number;
    },
) {
    const { billing_account_id, processor, amount } = params;
    return getResponseData(
        await apiClient.GET("/payment/final_topup_amount", {
            params: { query: { billing_account_id, processor, amount } },
        }),
    );
}

export function getRedirectUrlFromData(data: { redirect_url: string | string[] }) {
    const { redirect_url } = data;
    const url = Array.isArray(redirect_url) ? notNull(redirect_url[0], "redirect_url") : redirect_url;
    invariant(url, "Expected redirect_url in response");
    return url;
}

export async function invalidateTopUpQueries(queryClient: QueryClient, billing_account_id: number) {
    await invalidateQueries({
        queryClient,
        immediate: [billingAccountQuery.queryKey, billingHistoryQuery.getQueryKey({ billing_account_id })],
        background: [billingInvoicesQuery.getQueryKey({ billing_account_id })],
    });
}

export interface PollOptions {
    signal: AbortSignal;
    check: () => Promise<boolean>;
    popup: PaymentPopup | undefined;
    pollInterval?: number;
    maxPollCount?: number;
}

export type PollForSuccessResult = "SUCCESS" | "CANCELLED";

export async function pollForSuccess({
    signal,
    check,
    pollInterval = 5000,
    maxPollCount = 15,
    popup,
}: PollOptions): Promise<PollForSuccessResult> {
    try {
        let hasClosed = false;
        let hasReturned = false;
        // NB: This loop is exited with an abort error whenever the signal is set (eg. when the component is unmounted)
        for (let pollCount = 0; ; pollCount++) {
            if (maxPollCount != null && pollCount > maxPollCount) {
                throw new PaymentPollTimeoutError();
            }
            await sleep(pollInterval, signal);

            if (popup) {
                console.debug("%s: Checking popup state: %o", pollCount, popup.toString());

                // Log various events for debugging purposes
                if (!hasReturned && popup.hasReturned()) {
                    console.info("Popup has returned");
                    hasReturned = true;
                }

                if (!hasClosed && popup.closed) {
                    console.info("Popup has closed");
                    hasClosed = true;
                    // TODO: Handle this case? Status message should go back to main window
                }
            }

            if (await check()) {
                return "SUCCESS";
            }
        }
    } catch (e) {
        if (e instanceof UnmountedError) {
            console.debug("Unmounted, stopping polling");
            return "CANCELLED";
        } else {
            throw e;
        }
    }
}

export class PaymentPollTimeoutError extends Error {
    name = "PaymentPollTimeoutError";

    constructor() {
        super(
            "Response seems to be taking more time than expected. Wait a bit and refresh the page to see credit changes reflected. If it doesn't happen in a reasonable time, please contact support.",
        );
    }
}

export function failureToMessage({
    failure_code,
    failure_message,
}: {
    failure_code?: string;
    failure_message?: string;
}) {
    let message;
    if (failure_message) {
        message = `Payment error: ${failure_message}`;
    } else {
        message = "Unknown payment error";
    }
    if (failure_code) {
        message += ` [${failure_code}]`;
    }
    return message;
}
