import type { PersistentDataResponse } from "@warrenio/api-spec/spec.oats.gen";
import type { EmptyObject, LiteralUnion } from "type-fest";
import type * as billingCardsQuery from "../billingCardsQuery.ts";
import { failureToMessage, pollForSuccess, type PollOptions } from "../topup/topUpUtils.ts";

export interface OmiseChargeData {
    object: string;
    status: "pending" | "successful" | "failed";
    failure_code?: LiteralUnion<"payment_cancelled", string>;
    failure_message?: string;
}

export class OmiseError extends Error {
    name = "OmiseError";
    constructor(
        public readonly code: "payment_cancelled" | "other",
        message: string,
    ) {
        super(message);
    }
}

export function parseOmisePersistentData(response: PersistentDataResponse) {
    const data = JSON.parse(response.data) as OmiseChargeData | EmptyObject;
    if (!("object" in data)) {
        return null;
    }

    switch (data.status) {
        case "pending":
            return null;
        case "successful":
            return data;
        default:
            throw new OmiseError(
                data.failure_code === "payment_cancelled" ? "payment_cancelled" : "other",
                failureToMessage(data),
            );
    }
}

/** Small utility shared between link-type and card-type methods */
export async function pollForOmiseSuccess(
    options: Omit<PollOptions, "check">,
    getPersistentData: () => Promise<PersistentDataResponse>,
) {
    return await pollForSuccess({
        check: async () => parseOmisePersistentData(await getPersistentData()) != null,
        ...options,
    });
}

class OmiseCardVerificationError extends OmiseError {
    name = "OmiseCardVerificationError";
    constructor(public readonly cardId: number) {
        super("other", "Card verification failed");
    }
}

export async function pollForOmiseCardValidationSuccess(
    options: Omit<PollOptions, "check">,
    cardId: number,
    getCards: () => Promise<billingCardsQuery.Response>,
) {
    return await pollForSuccess({
        check: async () => {
            const cards = await getCards();
            const card = cards.find((c) => c.id === cardId);
            if (card?.is_verified === false && card?.is_verification_pending === false) {
                throw new OmiseCardVerificationError(cardId);
            }
            return card?.is_verified === true;
        },
        ...options,
    });
}
