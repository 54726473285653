import { useEffect } from "react";
import { useOnce } from "./useOnce.ts";

export class UnmountedError extends Error {
    name = "UnmountedError";
}

/** Aborts with an {@link UnmountedError} when the component is unmounted */
export function useUnmountSignal() {
    const controller = useOnce(() => new AbortController());
    useUnmountAbort(controller);
    return controller.signal;
}

/** @see {@link useUnmountSignal} */
export function useUnmountAbort(controller: AbortController) {
    useEffect(() => {
        // Abort on unmount
        return () => {
            console.debug("Unmount, sending abort signal");
            controller.abort(new UnmountedError());
        };
    }, [controller]);
}
